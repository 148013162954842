.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Purple-Grid-Title{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 3vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1.5vw;
  padding-bottom: 0.1vw;
  align-items:center;
  justify-content: center;
}

.Purple-Grid-Title-Phone{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 8vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1.5vw;
  padding-bottom: 0.1vw;
  align-items:center;
  justify-content: center;
  visibility: collapse;
}

.Purple-Grid-Info{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 1.5vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 1.5vw;
  align-items:center;
  justify-content: center;
}

.Purple-Grid-Info-Phone{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 5vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 1.5vw;
  align-items:center;
  justify-content: center;
  visibility: collapse;
}

.Purple-Grid-Contact{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 0.8vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 0.5vw;
  padding-bottom: 1.5vw;
  align-items:center;
  justify-content: center;
} 

.Purple-Grid-Contact-Phone{
  background-color:#C3B1E1;
  font-family: Arial;
  font-size: 3vw;
  color: #ffffff;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 0.5vw;
  padding-bottom: 1.5vw;
  align-items:center;
  justify-content: center;
  visibility: collapse;
} 

.White-Grid-Title{
  background-color:#ece3e3 ;
  font-family: Arial;
  font-size: 2vw;
  color: #343434;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1.5vw;
  padding-bottom: 0.1vw;
  align-items:center;
  justify-content: center;
}

.White-Grid-Info{
  background-color:#ece3e3 ;
  font-family: Arial;
  font-size: 1.5vw;
  color: #343434;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  align-items:center;
  justify-content: center;
  line-height: 1.2;
}


.gif{
  align-items:center;
  justify-content: center;
  width:10vw;
  height: 10vw;
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.fox{
  align-items:center;
  justify-content: center;
  width:10vw;
  height: 5vw;
  margin-left:auto;
  margin-right:auto;
  margin-top:4vw;
  display:block;
}

.cassandra{
  align-items:center;
  justify-content: center;
  width:7vw;
  height: 11vw;
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.fitImage{
  align-items:center;
  justify-content: center;
  width:100%;
  height: 100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.mainApp{
  padding:2vw;
}

@media only screen and (max-width:414px){
  .Purple-Grid-Contact{
    font-size: 1.5vw;
  }
  .Purple-Grid-Info{
    font-size: 5vw;
  }
  .Purple-Grid-Title{
    font-size: 8vw;
  }

  .White-Grid-Info{
    font-size: 3.5vw;
  }
  .White-Grid-Title{
    font-size: 5vw;
  }

  .gif{
    width: 40vw;
    height: 40vw;
  }

  .cassandra{
    width:25vw;
    height:40vw;
  }

  .fox{
    width: 35vw;
    height: 25vw;
    margin-top: 8vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
